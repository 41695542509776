<template>
  <div class="login">
    <h2>后台管理</h2>
    <div class="form">
      <el-form
        :rules="loginFormRules"
        ref="loginFormRef"
        label-width="0px"
        class="login_form"
        :model="loginForm"
      >
        <!--用户名-->
        <el-form-item prop="username">
          <el-input
            v-model="loginForm.username"
            prefix-icon="el-icon-user"
          ></el-input>
        </el-form-item>
        <!--密码-->
        <el-form-item prop="password">
          <el-input
            type="password"
            v-model="loginForm.password"
            prefix-icon="el-icon-lock"
          ></el-input>
        </el-form-item>
        <!--按钮区-->
        <el-form-item class="btns">
          <el-button type="primary" @click="login" :loading="loginLoading"
            >登录</el-button
          >
          <!-- <el-button type="info" @click="resetLoginForm">重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { PostLogin } from "@/request/api";
export default {
  name: "HtglLogin",

  data() {
    return {
      loginLoading: false, // 登录限制
      loginForm: {
        // 登录的表单数据的绑定对象
        username: "",
        password: "",
      },
      loginFormRules: {
        // 验证用户名是否合法
        username: [
          { required: true, message: "请输入登录名称", trigger: "blur" },
          {
            min: 3,
            max: 20,
            message: "长度在 3 到 20 个字符",
            trigger: "blur",
          },
        ],
        // 验证密码是否合法
        password: [
          { required: true, message: "请输入登录密码", trigger: "blur" },
          {
            min: 6,
            max: 15,
            message: "长度在 6 到 15 个字符",
            trigger: "blur",
          },
        ],
      },
      token: "",
    };
  },

  mounted() {},

  methods: {
    resetLoginForm() {
      // 点击重置按钮,重置登录表单
      // this.$refs[loginFormRef].resetFields()
      this.$refs.loginFormRef.resetFields();
    },
    login() {
      PostLogin({
        password: this.loginForm.password,
        username: this.loginForm.username,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          if (res.data.user.roleId == 8 || res.data.user.roleId == 5) {
            this.$message.error("企业或执法人员账号不能登录后台");
          } else {
            this.token = "Bearer " + res.data.token;
            localStorage.setItem("token", this.token);
            this.$router.push("/");
          }
        } else {
          this.$message.error(res.message);
        }
      });
      // this.loginLoading = true;
      // this.$refs.loginFormRef.validate(async (valid) => {
      //   if (!valid) {
      //     return (this.loginLoading = false);
      //   }
      //   const { data: res } = await this.$http.post("login", this.loginForm);
      //   if (res.meta.status !== 200) {
      //     this.loginLoading = false;
      //     return this.$message.error("登录失败 帐号或密码错误!");
      //   }
      //   this.$message.success("登录成功!");
      //   // 1. 将登录成功之后的 token,保存到客户端的 sessionStorage(会话机制/只在当前页面生效)中 localStorage(持久话机制/关闭页面也不会忘记数据)
      //   //   1.1 项目中除了登录之外的API接口,必须在登录之后才能访问
      //   //   1.2 token 只应在当前网站打开期间生效, 所以将 token 保存在 sessionStorage中
      //   window.sessionStorage.setItem("token", res.data.token);
      //   // 2. 通过编程式路由导航跳转到后台主页,路由地址是 /home
      //   this.$router.push("/home");
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100vh;
  background-color: #2d3a4b;
  padding-top: 200px;
  h2 {
    color: #fff;
  }
  .form {
    width: 500px;
    margin: 0 auto;
  }
}
.el-input__inner {
  background-color: #2d3a4b !important;
}
</style>